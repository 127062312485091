module.exports = {
  TW: {
    customers: {
      tradewinds: {
        title: 'TradeWinds',
        description: 'The Weekly International Shipping News',
        showTitle: false,
        requireLogin: true,
      },
      twplus: {
        title: 'TW+',
        description: 'The Quarterly TradeWinds Publication',
        showTitle: false,
        requireLogin: true,
      },
      twbusinessfocus: {
        title: 'Special Reports',
        description: 'High-level analysis of every field in shipping',
        showTitle: false,
        requireLogin: true,
      }
    },
    promobox: {
      frontpage: 'tradewinds',
      test_frontpage: 'tradewinds'
    },
    sectionIds: {
      Tradewinds: 'tradewinds',
      'TW+': 'twplus',
      'Special Reports': 'twspecialreports'
    }
  },
  UPS: {
    customers: {
      upstreamonline: {
        title: 'Archive',
        description: 'The global oil, gas and decarbonisation news source.',
        showTitle: false,
        requireLogin: true
      },
      upstreamfocusedition: {
        title: 'Focus Editions',
        description: 'In-depth analyses of industry technology developments.',
        showTitle: true,
        requireLogin: true
      },
      upstreamtechnology: {
        title: 'Upstream Technology',
        description: 'Covering the spectrum of oil and gas technology.',
        showTitle: false,
        requireLogin: false
      },
      upstreamshowdailies: {
        title: 'Show Dailies',
        description: 'Daily Upstream newspapers from top industry events worldwide.',
        showTitle: true,
        requireLogin: false
      }
    },
    promobox: {
      frontpage: 'upstreamonline',
      test_frontpage: 'upstreamonline'
    },
    sectionIds: {
      Weekly: 'upstreamonline',
      'Focus Editions': 'upstreamfocusedition',
      'Upstream Technology': 'upstreamtechnology',
      'Show Dailies': 'upstreamshowdailies'
    }
  },
  RE: {
    customers: {
      recharge: {
        title: 'Recharge',
        description: 'The global source for renewable energy news and intelligence.',
        showTitle: false,
        requireLogin: false,
        tempUrl: true
      }
    },
    promobox: {
      frontpage: 'recharge',
      test_frontpage: 'recharge'
    },
    sectionIds: {
      Recharge: 'recharge',
    }
  },
  IFM_COM: {
    hideEditionsPagePaywall: false,
    customers: {
      intrafishaquaculture: {
        title: 'IntraFish Aquaculture',
        description: 'The leading publication covering the global aquaculture industry.',
        showTitle: false,
        requireLogin: false,
        tempUrl: true,
      },
      intrafishfisheries: {
        title: 'IntraFish Fisheries',
        description: 'The leading global publication for the international fishing industry.',
        showTitle: false,
        requireLogin: false,
        tempUrl: true,
      },
      intrafishmarketplace: {
        title: 'IntraFish Magazine',
        description: 'Our quarterly printed edition delivers the best seafood news to tens of thousands of readers around the globe.',
        showTitle: false,
        requireLogin: false,
        tempUrl: true,
      },
      intrafishprocessor: {
        title: 'IntraFish Processor',
        description: 'Quarterly insights into the latest in processing equipment, innovation, packaging and food safety.',
        showTitle: false,
        requireLogin: false,
        tempUrl: true,
      }
    },
    promobox: {
      frontpage: 'intrafishmarketplace',
      test_frontpage: 'intrafishmarketplace'
    },
    sectionIds: {
      'IntraFish Aquaculture': 'intrafishaquaculture',
      'IntraFish Fisheries': 'intrafishfisheries',
      'IntraFish Magazine': 'intrafishmarketplace',
      'IntraFish Processor': 'intrafishprocessor'
    }
  },
  IFM_NO: {
    customers: {
      sjoematmagasinet: {
        title: 'Intrafish Magasin',
        description: '',
        showTitle: true,
        requireLogin: false,
        tempUrl: true
      }
    },
    promobox: {
      frontpage: 'sjoematmagasinet',
      test_frontpage: 'sjoematmagasinet'
    },
    sectionIds: {
      'Intrafish Magasin': 'sjoematmagasinet',
    }
  },
  EURO: {
    customers: {
      energino: {
        title: 'Energi',
        description: 'Fornybarbransjens ledende aktualitetsmagasin.',
        showTitle: true,
        requireLogin: true
      },
      energidistriktsenergino: {
        title: 'Energi Distrikt',
        description: 'Ledende magasin om Distriktsenergi.',
        showTitle: true,
        requireLogin: true
      },
      energifjernvarmeno: {
        title: 'Energi Fjernvarme',
        description: 'Med fire årlige utgaver som bilag til Energi, er Energi Fjernvarme den ledende publikasjon for fjernvarmebransjen.',
        showTitle: true,
        requireLogin: true
      },
      energismaakraftno: {
        title: 'Energi Småkraft',
        description: 'Ledende magasin om Småkraft Energi.',
        showTitle: true,
        requireLogin: true
      },
      energiutdanningno: {
        title: 'Energi Utdanning',
        description: 'Energi Utdanning viser vei til spennende karrieremuligheter.',
        showTitle: true,
        requireLogin: false
      },
      energivarenergino: {
        title: 'Vår Energi',
        description: 'Kundemagasinet som styrker lokal forankring og bygger omdømme.',
        showTitle: true,
        requireLogin: false
      }
    },
    promobox: {
      frontpage: 'energino',
      test_frontpage: 'energino'
    },
    sectionIds: {
      Europower: 'energino',
      'Energi Distriktsenergi': 'energidistriktsenergino',
      'Energi Fjernvarme': 'energifjernvarmeno',
      'Energi Småkraft': 'energismaakraftno',
      'Energi Utdanning': 'energiutdanningno',
      'Vår Energi': 'energivarenergino',
    }
  },
  FBFI: {
    customers: {
      fiskeribladet: {
        title: 'Fiskeribladet',
        description: 'Fiskeribladet tilbyr nyheter knyttet til fiskeri og havbruk. Vi f&#248;lger ogs&#229; norsk sj&#248;mat ut i den store verden.',
        showTitle: false,
        requireLogin: true,
      },
      sjoematmagasinet: {
        title: 'Intrafish Magasin',
        description: '',
        showTitle: true,
        requireLogin: false,
        tempUrl: true
      }
    },
    promobox: {
      frontpage: 'fiskeribladet',
      test_frontpage: 'fiskeribladet'
    },
    sectionIds: {
      Fiskeribladet: 'fiskeribladet'
    }
  },
  NHSTMVP: {
    customers: {
    },
    promobox: {
    },
    sectionIds: {
    }
  },
  HYDROGEN: {
    customers: {
    },
    promobox: {
    },
    sectionIds: {
    }
  },
  KYSTEN: {
    customers: {
    },
    promobox: {
    },
    sectionIds: {
    }
  }
};
